import { CompanyProfile } from '@company/models';
import { CompanyDto, EnhancedCompanyDto } from './company.dto';
import { LegalEntityState } from '@mkp/legal-entity/data-access';
import { EMPLOYEE_COUNT_RANGE } from '@app/config/metadatas/constants';
import { MinMax } from '@app/config/metadatas/models';

export const mapEnhancedCompanyDtoToCompanyProfile = (dto: EnhancedCompanyDto): CompanyProfile => {
  const { employeeCountFrom, employeeCountTo } = mapEmployeeCount(
    dto.employeeCountFrom,
    dto.employeeCountTo
  );

  return {
    id: dto.id,
    name: dto.name,
    legalEntityId: dto.legalEntityId,
    legalEntityName: dto.legalEntityName,
    legalEntityAddress: dto.legalEntityAddress,
    legalEntityState: isValidState(dto.legalEntityState) ? dto.legalEntityState : undefined,
    legalEntityVersion: dto.legalEntityVersion,
    headerMediaApiId: dto.headerMediaApiId || undefined,
    logoMediaApiId: dto.logoMediaApiId || undefined,
    description: dto.description,
    employeeCountFrom: dto.employeeCountFrom,
    employeeCountTo: dto.employeeCountTo,
    benefits: dto.benefits,
    links: dto.links,
    videos: dto.videos,
    companySize: `${employeeCountFrom}-${employeeCountTo}`,
    _version: dto._version,
    locations: dto._embedded?.locations,
    _embedded: {
      locations: dto._embedded?.locations,
    },
  };
};

export const mapCompanyProfileToCompanyRequestBody = (
  profile: Partial<CompanyProfile> & Pick<CompanyProfile, 'id' | '_version'>
): Partial<CompanyDto> => {
  return {
    id: profile.id,
    name: profile.name,
    legalEntityId: profile.legalEntityId,
    headerMediaApiId: profile.headerMediaApiId,
    logoMediaApiId: profile.logoMediaApiId,
    description: profile.description,
    employeeCountFrom: profile.employeeCountFrom,
    employeeCountTo: profile.employeeCountTo,
    benefits: profile.benefits,
    links: profile.links,
    videos: profile.videos,
    _version: profile._version,
  };
};

export const isValidState = (state: unknown): state is LegalEntityState =>
  Object.values(LegalEntityState).includes(state as LegalEntityState);

export const mapEmployeeCount = (
  minInput: number,
  maxInput: number
): Pick<CompanyProfile, 'employeeCountFrom' | 'employeeCountTo'> => {
  const { min: closestMin, max: backupMax } = getClosestRange(minInput);
  const { max: closestMax } = getClosestRange(maxInput);

  return {
    employeeCountFrom: closestMin,
    // use backupMax if max is somehow lower than min
    employeeCountTo: closestMax > closestMin ? closestMax : backupMax,
  };
};

export const getClosestRange = (value: number): MinMax =>
  EMPLOYEE_COUNT_RANGE.find(({ max }) => value <= max) ?? EMPLOYEE_COUNT_RANGE[0];
