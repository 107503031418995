import { LocationDto } from './location.dto';
import { Location, LocationRequestBody } from './location.model';

export const mapLocationDtoToLocation = (dto: LocationDto): Location => ({
  id: dto.id,
  companyProfileId: dto.companyProfileId,
  address: dto.address, // no mapping for address for now, but we could add one in the future
  createdAt: new Date(dto.createdAt),
  _version: dto._version,
});

export const mapLocationToLocationRequestBody = (location: Location): LocationRequestBody => ({
  companyProfileId: location.companyProfileId,
  address: location.address,
  _version: location._version,
});
