import { vacancyApiActions } from '@app/features/vacancy/store/actions/vacancy-api.actions';
import { EntityFeatureState, createEntityFeature } from '@mkp/shared/util-state';
import { Action, createReducer, on } from '@ngrx/store';
import { Location } from '@mkp/location/data-access';
import { loadLocationsFailure, loadLocationsSuccess } from '@store/actions/location-api.action';
import { resetLocations } from '@store/actions/location.action';

export type State = EntityFeatureState<Location, { message: string }>;

const { featureKey, initialState, updaters, selectors } = createEntityFeature<
  State,
  'locationState'
>('locationState');

const _reducer = createReducer(
  initialState,
  on(resetLocations, updaters.reset),
  on(updaters.setLoading),
  on(loadLocationsSuccess, (state, { locations }) =>
    updaters.getListSuccess(state, { entities: [...locations] })
  ),
  on(vacancyApiActions.loadLocationsSuccessAfterLocationCreation, (state, { locations }) =>
    updaters.getListSuccess(state, { entities: [...locations] })
  ),
  on(vacancyApiActions.loadLocationsSuccess, (state, { locations }) =>
    updaters.getListSuccess(state, { entities: [...locations] })
  ),
  on(loadLocationsFailure, updaters.setError)
);

function reducer(state: State | undefined, action: Action): State {
  return _reducer(state, action);
}

export { featureKey, initialState, reducer, selectors };
