import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  companyApiActions,
  companyExistsGuardActions,
  companyFormPageActions,
} from '@company/store/actions';
import { selectCompanyById, selectFirstCompanyByAccountId } from '@company/store/selectors';
import { AppConfig } from '@config/app.config';
import { MessageError, isMessageError } from '@core/models';
import { AccountMembership } from '@mkp/account-membership/data-access';
import { authActions, boAuthActions } from '@mkp/auth/actions';
import { CompanyResource } from '@mkp/company/data-access';
import { LocationResource, mapLocationToLocationRequestBody } from '@mkp/location/data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

@Injectable()
export class CompanyApiEffects {
  readonly updateCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyFormPageActions.updateCompany),
      switchMap(({ company }) => {
        return this.companyResource.updateCompany(company).pipe(
          map((updatedCompany) =>
            companyApiActions.updateCompanySuccess({ company: updatedCompany })
          ),
          catchError(
            (error: unknown) =>
              isCompanyApiError(error) &&
              of(companyApiActions.updateCompanyFailure({ companyId: company.id, error }))
          )
        );
      })
    );
  });

  readonly createCompanyLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyFormPageActions.createLocation),
      switchMap(({ location }) => {
        return this.locationResource.add(location).pipe(
          map(() =>
            companyApiActions.createCompanyLocationSuccess({ companyId: location.companyProfileId })
          ),
          catchError(
            (error: unknown) =>
              isCompanyApiError(error) &&
              of(
                companyApiActions.createCompanyLocationFailure({
                  companyId: location.companyProfileId,
                  error,
                })
              )
          )
        );
      })
    );
  });

  readonly updateCompanyLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyFormPageActions.updateLocation),
      switchMap(({ location }) => {
        return this.locationResource
          .update(location.id, mapLocationToLocationRequestBody(location))
          .pipe(
            map(() =>
              companyApiActions.updateCompanyLocationSuccess({
                companyId: location.companyProfileId,
              })
            ),
            catchError(
              (error: unknown) =>
                isCompanyApiError(error) &&
                of(
                  companyApiActions.updateCompanyLocationFailure({
                    companyId: location.companyProfileId,
                    error,
                  })
                )
            )
          );
      })
    );
  });

  readonly deleteCompanyLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyFormPageActions.deleteLocation),
      switchMap(({ location }) => {
        const { id, companyProfileId } = location;
        return this.locationResource.delete(id).pipe(
          map(() => companyApiActions.deleteLocationSuccess({ id, companyProfileId })),
          catchError(
            (error: unknown) =>
              isCompanyApiError(error) &&
              of(
                companyApiActions.deleteLocationFailure({
                  error,
                })
              )
          )
        );
      })
    );
  });

  readonly loadCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        companyApiActions.createCompanyLocationSuccess,
        companyApiActions.updateCompanyLocationSuccess
      ),
      switchMap(({ companyId }) => {
        return this.companyResource.getCompany(companyId).pipe(
          map((company) => companyApiActions.loadCompanySuccess({ company })),
          catchError(
            (error: unknown) =>
              isCompanyApiError(error) &&
              of(companyApiActions.loadCompanyFailure({ companyId, error }))
          )
        );
      })
    );
  });

  readonly loadCompanyIfNotExist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyExistsGuardActions.canActivate),
      concatLatestFrom(({ companyId }) => this.store.select(selectCompanyById(companyId))),
      filter(([, company]) => !company),
      switchMap(([{ companyId }]) => {
        return this.companyResource.getCompany(companyId).pipe(
          map((company) => companyApiActions.loadCompanyIfNotExistSuccess({ company })),
          catchError(
            (error: unknown) =>
              isCompanyApiError(error) &&
              of(companyApiActions.loadCompanyIfNotExistFailure({ companyId, error }))
          )
        );
      })
    );
  });

  readonly loadCompaniesAtLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.loadActiveAccountMembershipsSuccess),
      filter(({ accountMemberships }) => accountMemberships.length > 0),
      switchMap(({ accountMemberships }) => {
        return this.companyResource
          .getCompanies(getCompanyProfileByLegalEntityFilter(accountMemberships))
          .pipe(
            map(({ companyProfiles }) =>
              authActions.loadCompaniesAfterLoginSuccess({ companyProfiles })
            ),
            catchError(
              (error: unknown) =>
                isCompanyApiError(error) && of(authActions.loadCompaniesAfterLoginFailed({ error }))
            )
          );
      })
    );
  });

  readonly fetchAndSetCompanies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(boAuthActions.loadWorkspaceAccountLoadSuccess),
      concatLatestFrom(({ accounts: [{ id }] }) =>
        this.store.select(selectFirstCompanyByAccountId(id))
      ),
      switchMap(
        ([
          {
            accounts: [{ legalEntity }],
          },
          maybeCompanyProfile,
        ]) => {
          return maybeCompanyProfile
            ? of(
                boAuthActions.loadWorkspaceCompanyLoadSuccess({
                  companyProfiles: [maybeCompanyProfile],
                })
              )
            : this.companyResource
                .getCompanies({ filter: `legalEntity.id==${legalEntity.id}` })
                .pipe(
                  map(({ companyProfiles }) =>
                    boAuthActions.loadWorkspaceCompanyLoadSuccess({ companyProfiles })
                  ),
                  catchError((error: unknown) => {
                    this.router.navigate([AppConfig.routes.dashboard]);
                    return (
                      isCompanyApiError(error) &&
                      of(boAuthActions.loadWorkspaceCompanyLoadError({ error }))
                    );
                  })
                );
        }
      )
    );
  });

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly companyResource: CompanyResource,
    private readonly locationResource: LocationResource,
    private readonly router: Router
  ) {}
}

const isCompanyApiError = (error: unknown): error is MessageError =>
  isMessageError(error, 'CompanyApiEffects');

function getCompanyProfileByLegalEntityFilter(activeAccountMemberships: AccountMembership[]) {
  return {
    filter: activeAccountMemberships
      .map((accountMembership) => `legalEntity.id==${accountMembership.account.legalEntityId}`)
      .join(','),
  };
}
