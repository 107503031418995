import { Injectable } from '@angular/core';
import { ApiLink, BaseHttpResource, CoreListEnvelop } from '@mkp/shared/data-access';
import { map, Observable, of, switchMap, take } from 'rxjs';
import { CompanyDto, EnhancedCompanyDto } from './company.dto';
import { CompanyProfile } from '@app/features/company/models';
import { LegalEntityDto, LegalEntityResource } from '@mkp/legal-entity/data-access';
import { QueryOptions } from '@app/shared/models';
import {
  mapEnhancedCompanyDtoToCompanyProfile,
  mapCompanyProfileToCompanyRequestBody,
} from './company.mapper';

@Injectable({ providedIn: 'root' })
export class CompanyResource extends BaseHttpResource<CompanyDto, CoreListEnvelop<CompanyDto>> {
  constructor(private legalEntityResource: LegalEntityResource) {
    super('company-profile');
  }

  getCompany(companyId: string): Observable<CompanyProfile> {
    return this.getById(companyId).pipe(
      switchMap((company) => this.enhanceCompanyWithLegalEntity(company)),
      map(mapEnhancedCompanyDtoToCompanyProfile),
      take(1)
    );
  }

  getCompanies(query: QueryOptions): Observable<{
    companyProfiles: CompanyProfile[];
    totalCount: number;
    _links: { [key: string]: ApiLink };
    filter: string;
  }> {
    return this.getWithQuery(query).pipe(
      switchMap((response) => {
        const companies = response._embedded.results;

        return this.enhanceCompaniesWithLegalEntities(companies).pipe(
          map((enhancedCompanies) => ({
            companyProfiles: enhancedCompanies.map(mapEnhancedCompanyDtoToCompanyProfile),
            totalCount: companies.length,
            filter: query.filter || '',
            _links: response._links ? { ...response._links } : {},
          }))
        );
      }),
      take(1)
    );
  }

  updateCompany(
    company: Partial<CompanyProfile> & Pick<CompanyProfile, 'id' | '_version'>
  ): Observable<CompanyProfile> {
    const companyDto = mapCompanyProfileToCompanyRequestBody(company);

    return this.update(company.id, companyDto).pipe(
      switchMap((updatedCompany) => this.enhanceCompanyWithLegalEntity(updatedCompany)),
      map(mapEnhancedCompanyDtoToCompanyProfile)
    );
  }

  private enhanceCompaniesWithLegalEntities(
    companies: CompanyDto[]
  ): Observable<EnhancedCompanyDto[]> {
    const legalEntityIds = [...new Set(companies.map((company) => company.legalEntityId))];

    if (legalEntityIds.length === 0) {
      return of(companies as EnhancedCompanyDto[]);
    }

    return this.legalEntityResource.fetchLegalEntitiesByIds(legalEntityIds).pipe(
      map((legalEntities) => {
        const legalEntityMap = new Map(legalEntities.map((entity) => [entity.id, entity]));

        return companies.map((company) => {
          const legalEntity = legalEntityMap.get(company.legalEntityId);
          if (!legalEntity) return company as EnhancedCompanyDto;

          return enhanceCompanyWithLegalEntityData(company, legalEntity);
        });
      })
    );
  }

  private enhanceCompanyWithLegalEntity(company: CompanyDto): Observable<EnhancedCompanyDto> {
    return this.legalEntityResource
      .getById(company.legalEntityId)
      .pipe(map((legalEntity) => enhanceCompanyWithLegalEntityData(company, legalEntity)));
  }
}

const enhanceCompanyWithLegalEntityData = (
  company: CompanyDto,
  legalEntity: LegalEntityDto
): EnhancedCompanyDto => {
  return {
    ...company,
    legalEntityName: legalEntity.name,
    legalEntityAddress: legalEntity.address,
    legalEntityState: legalEntity.state,
    legalEntityVersion: legalEntity._version,
  };
};
