import { Injectable } from '@angular/core';
import { Location } from '@mkp/location/data-access';
import { BaseHttpResource, CoreListEnvelop } from '@mkp/shared/data-access';
import { LocationDto } from './location.dto';

@Injectable({ providedIn: 'root' })
export class LocationResource extends BaseHttpResource<
  LocationDto,
  CoreListEnvelop<LocationDto>,
  Location
> {
  constructor() {
    super('location');
  }
}
