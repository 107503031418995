import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, InjectionToken } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { QueryHeaders, QueryParams } from './query-params.model';

const API_URL = new InjectionToken('API Base Url', {
  factory: () => environment.api.core,
});

export const HIGH_LIMIT = 400;
export const QUERY_LIMIT_DEFAULT = 10;

export abstract class BaseHttpResource<Type, Envelop, Post = Type> {
  protected readonly http = inject(HttpClient);
  protected readonly apiUrl = inject(API_URL);

  protected get url(): string {
    return `${this.apiUrl}/${this.uri}`;
  }

  protected constructor(protected readonly uri: string) {}

  add(item: Post): Observable<Type> {
    return this.http.post<Type>(this.url, item);
  }

  delete(id: string | number): Observable<Type> {
    return this.http.delete<Type>(`${this.url}/${id}`);
  }

  getAll(): Observable<Envelop> {
    return this.http.get<Envelop>(this.url);
  }

  getById(id: string | number, headers: QueryHeaders = {}): Observable<Type> {
    return this.http.get<Type>(`${this.url}/${id}`, { ...getHeaders(headers) });
  }

  getWithHref(href: string): Observable<Envelop> {
    return this.http.get<Envelop>(`${this.url}?${href}`);
  }

  getWithQuery(params: Partial<QueryParams>, headers: QueryHeaders = {}): Observable<Envelop> {
    return this.http.get<Envelop>(this.url, { params, ...getHeaders(headers) });
  }

  update(
    id: string,
    body: Partial<Type> | Type,
    method: 'put' | 'patch' = 'patch'
  ): Observable<Type> {
    return this.http[method]<Type>(`${this.url}/${id}`, body);
  }
}

const getHeaders = ({ maxRetry }: QueryHeaders): { headers?: HttpHeaders } => ({
  ...(maxRetry ? { headers: new HttpHeaders({ maxRetry: `${maxRetry}` }) } : {}),
});
