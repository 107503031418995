import { createActionGroup, props } from '@ngrx/store';
import { Location } from '@mkp/location/data-access';
import { Vacancy } from '@vacancy/models/vacancy.model';

export const vacancyFormPageActions = createActionGroup({
  source: 'Vacancy Form Page',
  events: {
    'Set Default Company Profile': props<{ companyId: string }>(),
    'Update Vacancy': props<{ vacancy: Vacancy }>(),
    'Select Location': props<{ location: Location }>(),
  },
});
