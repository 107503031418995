import { CompanyProfile } from '@app/features/company/models';
import { UUID } from '@mkp/shared/data-access';
import { UpdateStr } from '@ngrx/entity/src/models';
import { createActionGroup, props } from '@ngrx/store';
import { Location } from '@mkp/location/data-access';
import { Vacancy } from '@vacancy/models/vacancy.model';

export const vacancyApiActions = createActionGroup({
  source: 'Vacancy API',
  events: {
    'Load Vacancy From Guard Success': props<{ vacancy: Vacancy }>(),
    'Load Vacancy From Guard Failure': props<{ vacancyId: string; error: { message: string } }>(),
    'Update Vacancy Success': props<{ update: UpdateStr<Vacancy> }>(),
    'Update Vacancy Failure': props<{ vacancyId: string; error: { message: string } }>(),
    'Update Vacancy Media Ids Success': props<{ update: UpdateStr<Vacancy> }>(),
    'Update Vacancy Media Ids Failure': props<{ error: { message: string } }>(),
    'List Vacancies Success': props<{
      vacancies: Vacancy[];
      _links: any;
      filter: any;
      totalCount: number;
    }>(),
    'List Vacancies Failure': props<{ error: { message: string } }>(),
    'Load More Vacancies Success': props<{
      vacancies: Vacancy[];
      _links: any;
      filter: any;
      totalCount: number;
    }>(),
    'Load More Vacancies Failure': props<{ error: { message: string } }>(),
    'Load Vacancies By Ids Success': props<{ vacancies: Vacancy[] }>(),
    'Load Vacancies By Ids Failure': props<{ error: { message: string } }>(),
    'Delete Draft Vacancy Success': props<{ id: string }>(),
    'Delete Draft Vacancy Failure': props<{ error: { message: string } }>(),
    'Create Company Location Success': props<{ companyId: UUID }>(),
    'Create Company Location Failure': props<{ companyId: UUID; error: { message: string } }>(),
    'Load Company Success': props<{ company: CompanyProfile }>(),
    'Load Company Failure': props<{ companyId: UUID; error: { message: string } }>(),
    'Load Locations Success': props<{ locations: Location[] }>(),
    'Load Locations Failure': props<{ error: { message: string } }>(),
    'Load Locations Success After Location Creation': props<{ locations: Location[] }>(),
  },
});
